<template>
  <div class="country-select-container">
    <DataEditor
      v-model="code"
      :items="countries"
      :show-label="false"
      :uimodel="uimodel"
      :is-custom-editor="true"
      :disabled="disabled"
      @restore="$emit('restore')"
      @remove="$emit('remove')"
    />
  </div>
</template>

<script>
import DataEditor from "../DataEditor";
export default {
  components: {
    DataEditor,
  },

  props: {
    value: {
      type: String,
      required: false,
    },

    uimodel: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      code: this.value,
    };
  },

  watch: {
    value(value) {
      this.code = value;
    },

    code(code) {
      this.$emit("input", code);
    },
  },

  computed: {
    countries() {
      const countries = this.$getCountries();
      return countries.map(({ code, name, emoji }) => {
        return {
          value: code,
          name,
          icon: emoji,
        };
      });
    },
  },
};
</script>

<style scoped>
.country-select-container {
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
}

.country-select-container > .data-editor,
.country-select-container > .data-editor::v-deep > .primitive-type-container {
  padding: 0px;
}

.country-select-container
  > .data-editor::v-deep
  > .primitive-type-container
  > .field-wrap {
  width: 100% !important;
}
</style>